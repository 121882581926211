/* Copyright © 2018-2020 Ganchrow Scientific, SA all rights reserved */
'use strict';

gsDefine('agent-selector-controller', ['manager-tools'], function(managerTools) {

  return class AgentSelectorController {
    constructor(element, filterRootAgent = true) {
      this.element = element;
      this.filterRootAgent = filterRootAgent;
    }

    async show(rootAgentName, agentList) {
      this.hide();
      let { agentTree, errors } = this.recursify(agentList, rootAgentName);

      if (errors.length) {
        console.error(errors); // eslint-disable-line
      }
      rootAgentName = rootAgentName.toUpperCase();

      this.agentTree = agentTree;
      this.element.classList.remove('-hidden');
      let rootAgent = this.agentTree[rootAgentName];
      this.rootAgentName = rootAgentName;

      this.generateTree(rootAgent, this._getElementById('agent-selector-body'));

      return new Promise(resolve => {
        let documentListener = event => {
          if (event.code === 'Escape') {
            cancel();
          }
        };
        let apply = () => {
          this._getElementById('agent-apply').removeEventListener('click', apply);
          this._getElementById('agent-cancel').removeEventListener('click', cancel);
          document.removeEventListener('keyup', documentListener);
          let checkedAgents = this.findCheckedAgents();
          this.hide();
          resolve(checkedAgents);
        };
        let cancel = () => {
          this._getElementById('agent-apply').removeEventListener('click', apply);
          this._getElementById('agent-cancel').removeEventListener('click', cancel);
          document.removeEventListener('keyup', documentListener);
          this.hide();
          resolve();
        };

        document.addEventListener('keyup', documentListener);
        this._getElementById('agent-apply').addEventListener('click', apply);
        this._getElementById('agent-cancel').addEventListener('click', cancel);
      });
    }

    hide() {
      this.element.classList.add('-hidden');
      managerTools.clearChildren(this._getElementById('agent-selector-body'));
    }

    _getElementById(id) {
      return this.element.querySelector('#' + id);
    }

    gsDestroy() {
      document.removeEventListener('keypress', this.documentListener);
    }

    recursify(agentList = [], rootAgentName) {
      let errors = [];
      let rootCount = 0;
      rootAgentName = rootAgentName ? rootAgentName.toUpperCase() : '';
      let superMaster;

      let agentTree = agentList.reduce((tree, agent) => {
        let upperAgent = (agent.agent || '').toUpperCase();
        let upperMaster = (agent.master || '').toUpperCase();
        if (!tree[upperAgent]) {
          tree[upperAgent] = { synthetic: false, children: [], name: upperAgent };
        } else if (!tree[upperAgent].synthetic) {
          errors.push(`Agent named ${agent.agent} is duplicated.`);
        } else {
          tree[upperAgent].synthetic = false;
        }

        if (rootAgentName === upperAgent) {
          superMaster = upperMaster;
        }

        if (upperMaster) {
          if (!tree[upperMaster]) {
            tree[upperMaster] = { synthetic: true, children: [], name: upperMaster };
          }
          tree[upperMaster].children.push(tree[upperAgent]);
        } else {
          rootCount++;
        }
        return tree;
      }, {});

      if (rootCount > 1) {
        errors.push('Multiple root agents found.');
      }

      if (agentTree[superMaster] && agentTree[superMaster].synthetic) {
        delete agentTree[superMaster];
      }

      Object.keys(agentTree).forEach(agentName => {
        if (agentTree[agentName].synthetic) {
          errors.push(`Agent ${agentName} not found.`);
        }
      });

      return { agentTree, errors };
    }

    generateTree(agent, containingElement, depth = 0) {
      if (depth > 12) {
        throw new Error('Agent cycle detected.');
      }

      let agentItem = document.createElement('li');
      containingElement.appendChild(agentItem);
      agentItem.classList.add('agent--item');

      let agentLabel = document.createElement('label');
      agentItem.appendChild(agentLabel);

      let agentCheck = document.createElement('input');
      agentLabel.appendChild(agentCheck);
      agentCheck.type = 'checkbox';
      agentCheck.classList.add('agent--check');
      agentCheck.checked = false;
      agentCheck.id = this.createAgentId(agent);
      agentCheck.dataset.agentName = agent.name.toUpperCase();

      let agentSpan = document.createElement('span');
      agentLabel.appendChild(agentSpan);
      agentSpan.textContent = agent.name;

      // Root agent handled differently
      if (depth === 0) {
        agentCheck.disabled = true;
        agentCheck.checked = true;
      }

      if (agent.children.length) {
        let checkChildren = document.createElement('button');
        agentItem.appendChild(checkChildren);
        checkChildren.type = 'button';
        checkChildren.id = this.createAgentId(agent) + '-toggle';
        checkChildren.addEventListener('click', () => this.toggleChildCheckboxes(agentItem, agentCheck.id));
        checkChildren.textContent = 'toggle sub-agents';
        checkChildren.classList.add('btn-xs');
        checkChildren.classList.add('btn');
        checkChildren.classList.add('agent--toggle');

        let childList = document.createElement('ul');
        agentItem.appendChild(childList);
        childList.classList.add('agent--list');

        agent.children.forEach(childAgent => {
          this.generateTree(childAgent, childList, depth + 1);
        });
      }
    }

    toggleChildCheckboxes(agentItem, excludeId, isChecked = undefined) {
      agentItem.querySelectorAll('.agent--check').forEach(childCheck => {
        if (childCheck.id === excludeId) {
          return;
        }
        childCheck.checked = isChecked === undefined ?
          isChecked = !childCheck.checked :
          isChecked;
      });
    }

    createAgentId(agent) {
      return `agent-${agent.name.replace(/\s+|\.+/g, '-')}`;
    }

    findCheckedAgents() {
      return Array.from(this.element.querySelectorAll('.agent--check'))
        .filter(checkbox => {
          if(this.filterRootAgent) {
            return checkbox.checked && checkbox.dataset.agentName !== this.rootAgentName;
          }
          return true;
        })
        .map(checkbox => this.agentTree[checkbox.dataset.agentName].name);
    }
  };
});
